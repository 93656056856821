.page-header {
	width: 100%;
	& .search {
		width: 100%;
		max-width: 30rem;
	}
}

@media only screen and (max-width: 575px) {
	.page-header {
		& .search {
			max-width: 90vw;
		}
	}
	.ant-page-header-heading-title {
		float: none;
		text-align: center;
	}
}
