.bottom-nav {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;
	justify-items: center;
	padding: 0;

	bottom: 0;
	position: fixed;
	width: 100%;
	z-index: 100;
	transition: transform 0.25s ease-in-out;
	will-change: transform;

	&.--hidden {
		transform: translateY(100%);
	}

	&.--disabled {
		transform: scaleY(0);
		z-index: -1;
	}

	& .nav-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 0.8rem;
	}
}
