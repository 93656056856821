.picture-header {
	color: #fff;
	z-index: 88;
	height: 100%;

	& .hCont {
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		// background-color: rgba(0, 12, 23, 0.63);
	}

	& .heading {
		color: #fff;
		margin: 10px;
		text-align: center;
		border-bottom: 1px solid white;
		padding-bottom: 10px;
	}

	& .card {
		width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
		margin: 2px auto;
		min-width: 250px;
	}

	& .media {
		height: 150px;
	}

	& .title {
		font-size: 14px;
	}

	& .pos {
		margin-bottom: 12px;
	}

	& .grid {
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}

	& .wrapper {
		width: 90%;
		margin: 0 auto;
	}

	& .action {
		margin-left: auto;
	}

	& .bgImgCont {
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%233182ce' fill-opacity='0.23'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	}

	& .bgImg {
		display: none;
	}
}
