.root {
	width: 100%;
}

.app {
	&_body {
		display: grid;
		grid-template-rows: auto 1fr auto;
		min-height: 100vh;
		flex-direction: column;
		font-size: calc(10px + 1.75vmin);
	}

	&_inner {
		display: grid;
		grid-template-rows: minmax(150px, auto) 1fr;
		align-items: center;
		&_mobile {
			padding-bottom: 3rem;
		}
	}

	&_link {
		color: #61dafb;
	}
}

.wrapper {
	margin: 0 auto;
	width: 100%;
	height: 100%;
}
