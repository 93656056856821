:root {
	--header-font: "PT Sans", sans-serif;
	--body-font: "Noto Sans", sans-serif;
	--primary-color: #1890ff;
	--accent-color: #0e66b9;
	--dim-color: #0e66b9;
	--dark-neutral-color: #444444;
	--background-color: #ffffff;
	--background-color-alt: #f5f5f5;
}

body {
	margin: 0;
	padding: 0;
	font-family: var(--body-font), -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
	height: auto;
	overflow-x: hidden;
}

/* custom scrollbar */
::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: var(--chakra-colors-whiteAlpha-700);
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--chakra-colors-whiteAlpha-600);
}

html[data-theme="light"] {
	& ::-webkit-scrollbar-thumb {
		background-color: var(--chakra-colors-blackAlpha-400);
		&:hover {
			background-color: var(--chakra-colors-blackAlpha-300);
		}
	}
}
